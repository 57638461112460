import React from "react";
const Banners = ({
  rev,
  title,
  id,
  image,
  description,
  json,
  subdescription,
}) => {
  return (
    <section className="banner-benefits">
      <div className="banner-benefits__item">
        <div
          className={`banner-benefits__item__body  ${
            id % 2 !== 0 ? "md:flex-row-reverse" : ""
          }`}
        >
          <div className={`banner-benefits__item__body__text `}>
            <h1 className="banner-benefits__item__body__text__title">
              {title}
            </h1>
            <p className="mb-2">{description}</p>
            {subdescription && <p>{subdescription}</p>}
          </div>

          <div className="banner-benefits__item__body__image">{image}</div>
        </div>
      </div>
    </section>
  );
};

export default Banners;
