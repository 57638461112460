import React from "react";

const ThreeColumnGridCard = ({ card }) => {
  return (
    <div className="three-col-grid-card">
      {card?.icon && (
        <div className="three-col-grid-card__icon">
          {typeof card?.icon !== "object" ? (
            <img src={card.icon} alt="card.title" />
          ) : (
            card.icon
          )}
        </div>
      )}
      <h3 className="three-col-grid-card__title">{card.title}</h3>
      <p className="three-col-grid-card__body">{card.summary}</p>
    </div>
  );
};

export default ThreeColumnGridCard;
