import React from "react";
import Container from "@layout/container";
import ChaosHero from "@components/chaos-engineering/hero";
import Banner from "@components/chaos-engineering/banner";
import Features from "@components/chaos-engineering/features";
import ThreeColumnsGridCards from "@components/common/gridCards/ThreeColumnsGridCards";
import { gridCardsData } from "@components/chaos-engineering/contents";
import chaosImage from "../../images/chaos/chaos_1.png";
import chaosImageOne from "../../images/chaos/chaos.svg";
import Meta from "@components/seo/Meta";
let heroData = {
  title: () => <>Harness Chaos Engineering</>,
  description:
    "アプリケーションの耐障害性を向上させ、コストのかかるダウンタイムを削減。",
  subDescription:
    "コントロールされた「カオス」実験を行い、リアルワールドのストレスや障害がシステムにどのような影響を与えるかを理解できます。より信頼性の高いアプリケーションやインフラストラクチャーの構築が可能になります。",
  btn: "お問い合わせ",
};

let bannerData = [
  {
    id: 1,
    title: "テストの完了からカオスをスタート",
    description:
      "CI/CDパイプラインでのテストは、高品質のソフトウェアを作成するのに役立ちます。しかし、ソフトウェアが実際にデプロイされたときにシステムに影響を与える可能性のある信頼性ストレスは考慮されることがありません。",
    subdescription:
      "カオスエンジニアリングは、デプロイメントとそれらが実行されるインフラストラクチャーを組み合わせ、現実世界の障害シナリオにシステムがどのように対応するかを実証することで、従来のテストを超えた成果を提供しします。",
    image: (
      <img objectFit="cover" loading="lazy" src={chaosImageOne} alt="chaos" />
    ),
  },
  {
    id: 2,
    title: "システムレベルの耐障害性を実現し、ダウンタイムを回避",
    description:
      "Harness Chaos Engineeringは、制御されたシステムレベルの障害実験によってシステムの弱点を発見し、将来的にそのような障害を発生させないために必要な情報を提供します。その結果、システムの信頼性が向上し、ダウンタイムが減少します。",
    image: (
      <img loading="lazy" objectFit="cover" src={chaosImage} alt="chaos one" />
    ),
  },
];

const ChaosEnginner = () => {
  const defaultMeta = {
    title:
      "Harness Chaos Engineering｜Harness正規販売代理店 DXable",
    description:
      "アプリケーションの耐障害性を向上させ、コストのかかるダウンタイムを削減するために、Harness Chaos Engineeringをご利用ください。",
    url: "https://harness.dxable.com/chaos-engineering",
  };
  return (
    <Container>
      <Meta meta={defaultMeta} />
      <ChaosHero {...heroData} />
      {bannerData.map((ban) => (
        <Banner key={ban.id} {...ban} />
      ))}
      <Features />
      <div className="pb-[24px]">
        <ThreeColumnsGridCards
          title="Harness Chaos Engineeringのしくみ"
          cards={gridCardsData}
          bg="#fff"
        />
      </div>
    </Container>
  );
};

export default ChaosEnginner;
