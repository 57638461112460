import React from "react";
import { Link } from "gatsby";
import { BsArrowRight } from "react-icons/bs";
import chaosOne from "../../../images/chaos/features/chaos-1.png";
import chaosTwo from "../../../images/chaos/features/chaos-2.png";
import chaosThree from "../../../images/chaos/features/chaos-3.png";
import chaosFour from "../../../images/chaos/features/chaos-4.png";
import chaosFive from "../../../images/chaos/features/chaos-5.png";
import chaosSix from "../../../images/chaos/features/chaos-6.png";
export const harnessPlatformFacts = [
  {
    id: 1,
    title: "障害のポイントに積極的に対処",
    image: (
      <img
        src={chaosOne}
        width={60}
        height={60}
        objectFit="cover"
        alt="障害のポイントに積極的に対処"
        loading="lazy"
      />
    ),
    content:
      "Harness Chaos Engineeringは、高度に制御された実世界の障害シナリオでシステムがどのように動作するかを理解し、システムの信頼性を高め、ダウンタイムの回避を支援します。",
  },
  {
    id: 2,
    title: "CI/CDパイプラインの統合",
    image: (
      <img
        src={chaosTwo}
        width={60}
        height={60}
        objectFit="cover"
        alt="障害のポイントに積極的に対処"
        loading="lazy"
      />
    ),
    content:
      "システムの信頼性の維持およびダウンタイムによるコストの回避はチーム戦です。カオスはSREだけのものではありません。Harnessでは、開発、DevOps、SRE、その他ソフトウェア開発ライフサイクルに関わる全てのチームを巻き込み、信頼性の高いシステムを作り上げます。",
  },
  {
    id: 3,
    title: "最大規模のカオス実験ライブラリー",
    image: (
      <img
        src={chaosThree}
        width={60}
        height={60}
        objectFit="cover"
        alt="障害のポイントに積極的に対処"
        loading="lazy"
      />
    ),
    content:
      "ポッド、ノード、ネットワーク、ストレス、クラウドサービス、アプリケーションのカオステストを網羅する、最大かつ最も多様なカオス実験セットを提供します。また、ユーザーは独自の実験を作成し、ChaosHubの公開カタログに投稿することができます。",
  },
  {
    id: 4,
    title: "完全な分離",
    image: (
      <img
        src={chaosFour}
        width={60}
        height={60}
        objectFit="cover"
        alt="障害のポイントに積極的に対処"
        loading="lazy"
      />
    ),
    content:
      "オンプレミス、エアギャップインストールをサポートしているため、Harness Chaos Engineeringでは、脆弱性が第三者にさらされるリスクを回避できます。",
  },
  {
    id: 5,
    title: "エンタープライズおよびプライベートChaosHub",
    image: (
      <img
        src={chaosFive}
        width={60}
        height={60}
        objectFit="cover"
        alt="障害のポイントに積極的に対処"
        loading="lazy"
      />
    ),
    content:
      "Harness Enterprise ChaosHubのカオス実験ライブラリーは、複数のクラウドプロバイダーやプラットフォームをカバーする「すぐに使える」実験の完全なカタログとして活用できます。UIを利用して、実験の管理、実行、スケジューリングを完全に制御し、チーム間のコラボレーションを容易にします。プライベートChaosHubカタログを作成し、自分自身の実験を保存したり、公開コミュニティーに貢献することができます。",
  },
  {
    id: 6,
    title: "観測可能なシステムとのインテグレーション",
    image: (
      <img
        src={chaosSix}
        width={60}
        height={60}
        loading="lazy"
        objectFit="cover"
        alt="障害のポイントに積極的に対処"
      />
    ),
    content:
      "DynatraceやPrometheusといった主要な観測システムとのインテグレーションにより、Harness Chaos Engineeringは、ソフトウェア開発ライフサイクルに関わる全ての人がカオス実験の結果を確認し、それに応じて信頼性を向上させることができます。",
  },
];

const Features = () => {
  return (
    <>
      <section className="chaos-features">
        <h1 className="chaos-features__title">
          Harness Chaos Engineeringを活用する理由
        </h1>

        <div className="chaos-features__cards-container">
          {harnessPlatformFacts.map((card) => (
            <FeatureCard data={card} key={card.title} />
          ))}
        </div>
      </section>
    </>
  );
};

export default Features;
const FeatureCard = ({ data }) => {
  const { title, subtitle, content, image, ctaLink, ctaName } = data;
  return (
    <div className="chaos-features__cards-container__card">
      <div className="chaos-features__cards-container__card__header">
        <div className="chaos-features__cards-container__card__header__img">
          {image}
        </div>

        <h2 className="chaos-features__cards-container__card__header__title">
          {title}
        </h2>
      </div>
      <h3 className="chaos-features__cards-container__card__subtitle">
        {subtitle}
      </h3>
      <p className="chaos-features__cards-container__card__content">
        {content}
      </p>
      {ctaLink && (
        <Link
          to={ctaLink}
          className="chaos-features__cards-container__card__cta"
        >
          <p className="chaos-features__cards-container__card__cta__text">
            {ctaName}
          </p>
          <div className="chaos-features__cards-container__card__cta__icon">
            <BsArrowRight size={20} />
          </div>
        </Link>
      )}
    </div>
  );
};
