import React from "react";
import Card1 from "../../images/chaos/cards/cardOne.svg";
import Card2 from "../../images/chaos/cards/cardTwo.svg";
import Card3 from "../../images/chaos/cards/cardThree.svg";
import Card4 from "../../images/chaos/cards/cardFour.svg";

export const gridCardsData = [
  {
    id: 1,
    title: "ChaosHubからカオス実験を選択する",
    summary:
      "AWS、GCP、Azure、またはセルフホスティング環境にまたがるKubernetesと非Kubernetesのターゲットに対して、実験を選択します。",
    icon: Card1,
    isSVG: true,
    height: "auto",
  },
  {
    id: 2,
    title: "ターゲットシステム上で一連のカオス実験を実行する",
    summary:
      "実験では、お客様のシステムにかかる実世界のストレスをシミュレートします。",
    icon: Card2,
    isSVG: true,
    height: "auto",
  },
  {
    id: 3,
    title: "実験結果の観察",
    summary:
      "お客様のシステムのどこに脆弱性があるのか、実験により明らかにします。",
    icon: Card3,
    isSVG: false,
    height: "auto",
  },
  {
    id: 4,
    title: "信頼性向上のための学習",
    summary: "システムの信頼性を向上させ、ダウンタイムリスクを低減します。",
    icon: Card4,
    isSVG: false,
    height: "auto",
  },
];
